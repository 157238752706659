import './cu_admission.css'
import BgImage from './cu_bg_1.jpg'
import { currencyConverter, formatDate, formatDateAndTime } from '../../../resources/url';

const AlAnsarAdmissionLetter = (props) => {
    const dt = props.data[0];

    const applicantCourse = dt.applicantCourse[0];
    const applicantInfo = dt.applicantInfo[0];
    const decison = dt.decison;
    const school = dt.school
    const title = dt.decison.type === "Conditional" ? "CONDITIONAL OFFER OF ADMISSION" : "OFFER OF PROVISIONAL ADMISSION";
    let cons_ = []
    if (dt.decison.con1 !== "") {
        cons_.push(...[dt.decison.con1])
    }
    if (dt.decison.con2 !== "") {
        cons_.push(...[dt.decison.con2])
    }
    if (dt.decison.con3 !== "") {
        cons_.push(...[dt.decison.con3])
    }
    if (dt.decison.con4 !== "") {
        cons_.push(...[dt.decison.con4])
    }
    if (dt.decison.con5 !== "") {
        cons_.push(...[dt.decison.con5])
    }
    if (dt.decison.con6 !== "") {
        cons_.push(...[dt.decison.con6])
    }
    return (
        <div>
            {
                props.data.length > 0 &&
                <div className="body" style={{
                    backgroundImage: `url(${BgImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: 'transparent !important',
                    paddingBottom: '10px'
                }} ref={props.componentRef} >
                    <div className="table-wrapper" style={{ marginLeft: "50px", marginRight: "50px", marginTop: "160px" }} >
                        <div>
                            <br />
                            <p className='p-text' >
                                Application ID: APP/{decison.semester}/{decison.ApplicationID}
                                <br />
                                Decision Date: {formatDateAndTime(formatDate(decison.InsertedOn), "date")}
                                <br />
                                Applicant Name: {applicantInfo.Surname} {applicantInfo.MiddleName} {applicantInfo.FirstName}
                                <br />

                            </p>
                            <div>Dear {applicantInfo.FirstName},</div>
                        </div>
                        <div className="" style={{ fontWeight: "bolder" }}>Offer of Provisional Admission</div>
                        <div style={{ marginTop: "-5px" }} >
                            Further to your application for admission to study at {school.name.split("|")[0].trim()}, I am pleased to inform you that you have been offered Provisional Admission to undertake the below programme:<br />
                            <div style={{ marginLeft: "100px" }}>
                                <div>
                                    <span className='font-weight-bold'>Title of Programme: {decison.CourseName}</span> <br />
                                    <span className='font-weight-bold'>Mode of Study: Full-Time</span> <br />
                                    <span className='font-weight-bold'>Admission Type: {decison.type}</span> <br />
                                    <span className='font-weight-bold'>Term of Admission: May 2024</span> <br />
                                    <span className='font-weight-bold'>Course Length: {applicantCourse.Duration + " " + applicantCourse.DurationType}</span> <br />
                                    <span className='font-weight-bold'>Fee Status: Home</span>
                                </div>
                            </div>
                            <div className="" style={{ fontWeight: "bolder" }}>Fees and other costs</div>
                            <div>
                                The Tuition Fee for the first year of the programme is stated below
                            </div>
                            <div style={{ marginLeft: "100px" }}>
                                <span className='font-weight-bold'>{currencyConverter(parseInt(applicantCourse.TuitionFee))} per semester</span> <br />
                                <span className='font-weight-bold'>{currencyConverter(parseInt(applicantCourse.TuitionFee) * 2)} per session</span> <br />
                                <span className='font-weight-bold'>N 450,000.00 Accommodation per semester</span> <br />
                                <span className='font-weight-bold'>N 20,000.00 Application Processing Fee</span>
                            </div>
                            {/*<div>*/}
                            {/*    As a pioneer student of the university, you are eligible for a 10% tuition discount. Other*/}
                            {/*    discounts include;*/}
                            {/*</div>*/}
                            {/*<div style={{ marginLeft: "100px" }}>*/}
                            {/*    <span className='font-weight-bold'>10% Discount Siblings</span><br />*/}
                            {/*    <span className='font-weight-bold'>10% Discount Straight As or 300+ in JAMB and an excellent transcript </span><br />*/}
                            {/*    <span className='font-weight-bold'>10% Discount GPA of 3.6 on a scale of 4 in their first semester</span>*/}
                            {/*</div>*/}
                            <br/>
                            <div> Please note that this offer of admission is subject to your acceptance of the conditions and undertaking.</div>
                            <div>Please accept my congratulations on your admission!</div>
                            <img src={require("./reg_sign.png")} style={{ width: "240px", height: '50px', marginTop: '15px' }} />
                            <div>
                                Mani Ibrahim Ahmad, PhD. FNIM<br />
                                Registrar
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}
export default AlAnsarAdmissionLetter